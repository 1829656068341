<template>
  <div>
    <div
      :style="getElementStyle"
      v-if="!loading && !profilePage && data.properties.filed_content != 'Hide'"
    >
      <el-row
        align="middle"
        v-if="
          !data.properties.hideLabel || data.properties.filed_content != 'Hide'
        "
      >
        <el-col
          v-if="!data.properties.hideLabel"
          :span="showLabel && !isDefalutPosq ? 4 : data.description ? 20 : 24"
          ref="colRef"
          :style="computedStyles"
          class="bottom-space"
        >
            <icons v-if="isIconRequired" :iconName="data.icon_name" class="mr-1"></icons>
            <label
              for="text"
              v-if="hasLabel && !data.properties.hideLabel"
              :style="getLabelStyles"
            >
              {{ label || data.label }}
            </label>
          <span
            v-if="data.validations.required && hasLabel && !isView"
            style="color: red; font-weight: bold"
          >
            *
          </span>
          <span>
            <el-popover
              v-if="data.description"
              placement="top-start"
              trigger="hover"
              :content="data.description"
            >
              <i class="el-icon-info" slot="reference"></i>
            </el-popover>
          </span>
        </el-col>
        <el-col
          v-else
          :span="showLabel ? 4 : data.description ? 20 : 24"
          ref="colRef"
          :style="computedStyles"
        >
          <span
            v-if="hasLabel"
            style="
              color: white;
              display: inline-block;
              height: 20px;
              opacity: 0;
            "
          ></span>
          <span
            v-if="data.validations.required && hasLabel && !isView"
            style="color: red; font-weight: bold"
          >
            *
          </span>
        </el-col>
        <el-col :span="showLabel && !isDefalutPosq ? 12 : 24">
          <template v-if="isList && !isView">
            <el-row>
              <el-col :span="data.properties.qr_code_scanning ? 22 : 24">
                <!-- **** isList filed is true  this field imported from Global variable ****-->
                <input
                  type="text"
                  :readonly="true"
                  :style="{ 'text-align': inputTextAlignment, ...getStyle }"
                  :placeholder="data.placeholder || data.label"
                  v-model="form[this.data.key]"
                  :required="data.validations.required"
                  :disabled="isFromDocument ? false : checkReadonly()"
                  :suffix-icon="data.properties.el_icon"
                  :minlength="
                    data.properties.minlength_value
                      ? data.properties.minlength_value
                      : 1
                  "
                  :maxlength="
                    data.properties.maxlength_value
                      ? data.properties.maxlength_value
                      : 64
                  "
                  @change="applyFormRules"
                  class="sle"
                  :class="{ 'disabled-field': checkReadonly() }"
                />
              </el-col>
              <el-col
                v-if="data.properties.qr_code_scanning"
                :span="data.properties.qr_code_scanning ? 2 : 24"
              >
                <span class="qrCodeBlock" v-if="!isView">
                  <el-popover
                    v-if="data.properties.qr_code_scanning"
                    placement="top-start"
                    trigger="hover"
                    :content="qrDescription"
                  >
                    <i
                      class="el-icon-camera size-2em"
                      slot="reference"
                      @click="enableQRReader"
                    ></i>
                  </el-popover>
                </span>
              </el-col>
            </el-row>
          </template>
        </el-col>
        <el-col
          v-if="
            data.input_type != 'PASSWORD' &&
            data.input_type != 'EMAIL' &&
            data.input_type != 'MASKED' &&
            data.input_type != 'HYPER_LINK'
          "
          class="d-flex"
          :span="showLabel && !isDefalutPosq ? 12 : 24"
        >
          <input
            type="text"
            v-if="!isView && !isList && data.properties.filed_content != 'Hide'"
            :style="{ 'text-align': inputTextAlignment, ...getStyle }"
            :placeholder="data.placeholder || data.label"
            v-model="form[data.key]"
            :required="data.validations.required"
            :disabled="isFromDocument ? false : checkReadonly()"
            :readonly="readonly()"
            :suffix-icon="data.properties.el_icon"
            :minlength="
              data.properties.minlength_value
                ? data.properties.minlength_value
                : 1
            "
            :maxlength="
              data.properties.maxlength_value
                ? data.properties.maxlength_value
                : 64
            "
            @change="applyFormRules"
            class="sle"
            :class="{
              'disabled-field': checkReadonly(),
              'highlight-border': highlight,
            }"
          />
          <i
            class="el-icon-refresh regenerate"
            v-if="data.ai_settings && data.ai_settings.selectedSourceField"
            :disabled="checkIsGenerateDisabled"
            @click="generateAIForField()"
          ></i>
        </el-col>
        <template
          v-if="
            data.input_type == 'EMAIL' &&
            data.input_type != 'PASSWORD' &&
            data.input_type != 'MASKED' &&
            data.input_type != 'HYPER_LINK'
          "
        >
          <el-col :span="showLabel && !isDefalutPosq ? 12 : 24">
            <input
              type="email"
              autocomplete="off"
              v-if="!isView"
              :style="{ 'text-align': inputTextAlignment, ...getStyle,
                width: data.width ? `${data.width}px` : 'auto' }"
              :placeholder="data.placeholder || data.label"
              v-model="form[data.key]"
              :required="data.validations.required"
              @blur="validateEmail"
              :disabled="isFromDocument ? false : checkReadonly()"
              :readonly="readonly()"
              :minlength="
                data.properties.minlength_value
                  ? data.properties.minlength_value
                  : 8
              "
              :maxlength="
                data.properties.maxlength_value
                  ? data.properties.maxlength_value
                  : 256
              "
              @change="applyFormRules"
              class="sle"
              :class="{
                'disabled-field': checkReadonly(),
                'highlight-border': highlight,
              }"
            />
          </el-col>
        </template>

        <template
          v-if="
            data.input_type == 'PASSWORD' &&
            data.input_type != 'EMAIL' &&
            data.input_type != 'MASKED' &&
            data.input_type != 'HYPER_LINK'
          "
        >
          <el-col :span="showLabel && !isDefalutPosq ? 12 : 24">
            <input
              type="password"
              v-if="!isView"
              :style="{ 'text-align': inputTextAlignment, ...getStyle }"
              :placeholder="data.placeholder || data.label"
              v-model="form[data.key]"
              :required="data.validations.required"
              :disabled="isFromDocument ? false : checkReadonly()"
              :readonly="readonly()"
              :minlength="
                data.properties.minlength_value
                  ? data.properties.minlength_value
                  : 1
              "
              :maxlength="
                data.properties.maxlength_value
                  ? data.properties.maxlength_value
                  : 32
              "
              @change="applyFormRules"
              class="sle"
              :class="{
                'disabled-field': checkReadonly(),
                'highlight-border': highlight,
              }"
            />
          </el-col>
        </template>
        <template
          v-if="
            data.input_type == 'MASKED' &&
            data.input_type != 'EMAIL' &&
            data.input_type != 'PASSWORD' &&
            data.input_type != 'HYPER_LINK'
          "
        >
          <el-col :span="showLabel && !isDefalutPosq ? 12 : 24">
            <input
              v-if="!isView && flag"
              :style="{ 'text-align': inputTextAlignment, ...getStyle }"
              :placeholder="data.placeholder || data.label"
              v-model="form[data.key]"
              v-mask-last-four
              :required="data.validations.required"
              :disabled="isFromDocument ? false : checkReadonly()"
              :readonly="readonly()"
              :minlength="
                data.properties.minlength_value
                  ? data.properties.minlength_value
                  : 1
              "
              @focus="realNumber"
              @blur="validateMasked"
              :maxlength="
                data.properties.maxlength_value
                  ? data.properties.maxlength_value
                  : 32
              "
              @change="applyFormRules"
              class="sle"
              :class="{
                'disabled-field': checkReadonly(),
                'highlight-border': highlight,
              }"
            />
          </el-col>
        </template>
        <template
          v-if="
            data.input_type == 'HYPER_LINK' &&
            data.input_type != 'MASKED' &&
            data.input_type != 'EMAIL' &&
            data.input_type != 'PASSWORD'
          "
        >
          <el-col :span="showLabel && !isDefalutPosq ? 12 : 24">
            <input
              v-if="!isView"
              :style="{ 'text-align': inputTextAlignment, ...getStyle }"
              :placeholder="data.placeholder || data.label"
              v-model="form[data.key]"
              :required="data.validations.required"
              :disabled="isFromDocument ? false : checkReadonly()"
              :readonly="readonly()"
              :minlength="
                data.properties.minlength_value
                  ? data.properties.minlength_value
                  : 1
              "
              :maxlength="
                data.properties.maxlength_value
                  ? data.properties.maxlength_value
                  : 400
              "
              @change="applyFormRules"
              class="sle"
              :class="{
                'disabled-field': checkReadonly(),
                'highlight-border': highlight,
              }"
            />
            <span
              style="color: red"
              v-if="form[data.key] && !isValidURL(form[data.key])"
            >
              Invalid URL
            </span>
          </el-col>
        </template>
        <el-col
          :span="showLabel && !isDefalutPosq ? 12 : 24"
          class="execute-view"
        >
          <p v-if="hasError" style="color: red; font-size: 14px">
            {{ errorMsg }}
          </p>
          <p v-if="isView" :style="getStyle" style="overflow-x: hidden">
            <template v-if="form[data.key]">
              <span v-if="data.input_type === 'MASKED'">{{
                form[`${data.key}_masked`]
              }}</span>
              <span v-else-if="data.input_type === 'PASSWORD'"
                >{{ password }} |<i
                  @mouseover="unHiddenPassword"
                  @mouseleave="hidePassword"
                  class="el-icon-view"
                ></i
              ></span>
              <span v-else-if="data.input_type === 'HYPER_LINK'">
                <el-link
                  @click="checkNavigationAction()"
                  style="color: rgb(69 188 237)"
                  >{{ form[data.key] }}</el-link
                >
              </span>
              <!-- <span v-else>{{ form[data.key] | truncate(20, "...")}}</span> -->
              <span v-else>
                <el-popover trigger="hover" placement="top-start">
                  <span slot="reference">{{
                    form[data.key] | truncate(22, "...")
                  }}</span>
                  {{ form[data.key] }}
                </el-popover>
              </span>
            </template>
            <template v-else>-</template>
          </p>
        </el-col>
      </el-row>
    </div>
    <div v-else>
      <div v-if="isView && data.properties.filed_content != 'Hide'">
        <span v-if="data.input_type === 'MASKED'">{{
          form[`${data.key}_masked`]
        }}</span>
        <span v-else-if="data.input_type === 'PASSWORD'"
          >{{ password }} |<i
            @mouseover="unHiddenPassword"
            @mouseleave="hidePassword"
            class="el-icon-view"
          ></i
        ></span>
        <span v-else-if="data.input_type === 'HYPER_LINK'">
          <el-link
            @click="checkNavigationAction()"
            style="color: rgb(69 188 237)"
            >{{ form[data.key] }}</el-link
          >
        </span>
        <span v-else> {{ form[data.key] }}</span>
      </div>
      <div v-else>
        <input
          type="text"
          v-if="data.properties.filed_content != 'Hide'"
          :placeholder="data.placeholder || data.label"
          v-model="form[data.key]"
          :required="data.validations.required"
          :disabled="checkReadonly()"
          :readonly="readonly()"
          :suffix-icon="data.properties.el_icon"
          :minlength="
            data.properties.minlength_value
              ? data.properties.minlength_value
              : 1
          "
          :maxlength="
            data.properties.maxlength_value
              ? data.properties.maxlength_value
              : 64
          "
          @change="applyFormRules"
          class="sle"
          :class="{
            'disabled-field': checkReadonly(),
            'highlight-border': highlight,
          }"
        />
      </div>
    </div>
    <dialog-component
      v-loading="qrLoader"
      :title="modalTitle"
      :visible="showQrReader"
      @before-close="cancelQRPopup"
      :containerWidth="'50vw'"
      :containerMaxHeight="'70vh'"
    >
      <QRReader
        v-if="showQrReader"
        :data="data"
        :form="form"
        :isFromTemplates="true"
        @before-close="cancelQRPopup"
      />
    </dialog-component>
  </div>
</template>
<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import templateConstants from "../../../constants/lg-en/templates";
import FieldStylesHelper from "@/mixins/FieldStylesHelper";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper";
import { mapGetters } from "vuex";
export default {
  name: "templates-formComponentsExecute-SingleLineTextExecute",
  components: {
    DialogComponent: () => import("../../customComponents/CustomDialog.vue"),
    QRReader: () => import("./QR_Reader.vue"),
  },
  mixins: [userPermissionsHelper, FieldStylesHelper, TemplateBuilderHelper],
  props: [
    "data",
    "label",
    "form",
    "isView",
    "hasLabel",
    "isFromDocument",
    "fromRepeatable",
    "checkIsDisabled",
    "profilePage",
    "colorFields",
    "highlight",
    "fromEntityViews",
    "authenticatedUserInfoFromFormbuilder",
    "isFromFormbuilderRepeatableTemplate",
    "fieldsData",
    "isIconRequired"
  ],
  mounted() {
    this.loading = true;
    //this.defaultMethod();
     this.defaultValueUpdate();
    this.setContactInfo();
    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      // if (this.data.styles.labelStyle == "right") {
      //   this.isDefalutPos = false;
      // }
    }
    // if (this.data.input_type === "MASKED") {
    //   // this.inputValue2 = this.form[this.data.key];
    //   this.form[this.data.key] = this.inputValue2
    //
    // }
    this.password = this.form[this.data.key]
      ? "*".repeat(this.form[this.data.key].length)
      : "";
    this.loading = false;
  },
  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),
    getStyle() {
      return this.data.styles &&
        this.data.styles.label_color &&
        !this.fromRepeatable
        ? `font-weight: 400; width: ${this.data.width}px;`
        : "";
    },

  //  getLabelStyles() {
  //     return this.data.styles && this.data.styles.label_color
  //       ? `color: ${this.data.styles.label_color}; `
  //       : "font-weight: 0";
  //   },

    computedStyles() {
      const styles = {};
      if (this.data?.properties?.hideLabel) {
        styles.display = "none";
       } else {
        styles.display = "flex";
        if (this.data?.styles?.labelAlignments) {
          styles.justifyContent = this.data.styles.labelAlignments;
        }
      }
      return styles;
    },
    checkIsGenerateDisabled() {
      if (
        this.data?.ai_settings?.selectedSourceField &&
        this.form[this.data.ai_settings.selectedSourceField]
      )
        return false;
      return true;
    },
    inputTextAlignment() {
      return this.textAlignment ? this.textAlignment : "left"; // Set a default value if not provided
    },

    isDefalutPosq() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
  },
  data() {
    return {
      isList: false,
      hasError: false,
      errorMsg: "",
      showLabel: true,
      //  isDefalutPos: true,
      loading: false,
      inputValue: "",
      maskedValue: "",
      inputValue2: "",
      hideLabel: false,
      password: "",
      textAlignment: "left",
      inputValue3: "",
      flag: true,
      qrDescription: "Scan QR/ Barcode",
      showQrReader: false,
      modalTitle: " Scan Here",
      qrLoader: false,
    };
  },
  directives: {
    maskLastFour: {
      inserted(el) {
        el.addEventListener("blur", (event) => {
          const input = event.target;
          const inputValue = input.value;
          let maskedValue;
          if (inputValue.length <= 4) {
            maskedValue = inputValue;
          } else {
            let lastFourDigits = inputValue.slice(-4);
            maskedValue = "*".repeat(inputValue.length - 4) + lastFourDigits;
          }
          input.value = maskedValue;

          // input.dispatchEvent(new Event("blur"));
        });
      },
    },
  },

  methods: {
    updateFullName() {
      if (this.form.first_name && this.form.last_name) {
        this.form.name = this.form.first_name + " " + this.form.last_name;
      } else {
        this.form.name = ""; // Set it to empty if either first name or last name is empty
      }
    },
    async generateAIForField() {
      try {
        this.$emit("loadComponent", {
          value: true,
          loadingText: "Generating Data...",
        });
        let form = await this.getAIFieldsResponse(
          this.fieldsData,
          [this.data],
          this.form,
          "OnClick"
        );
        this.form[this.data.key] = form[this.data.key];
        this.$emit("loadComponent", { value: false, loadingText: "" });
      } catch (error) {
        this.$emit("loadComponent", { value: false, loadingText: "" });
      }
    },
    defaultValueUpdate() {
      if (
        this.data.default_value &&
        this.form &&
        this.data.key &&
        !this.form[this.data.key] && !this.data.apply_default_at_end
      ) {
        //  this.form[this.data.key] = this.data.default_value;
        this.$set(this.form, this.data.key, this.data.default_value);
      }
    },
    setContactInfo() {
      if (
        this.isFromFormbuilderRepeatableTemplate &&
        this.authenticatedUserInfoFromFormbuilder
      ) {
        for (let key in this.authenticatedUserInfoFromFormbuilder) {
          if (this.data.key == key) {
            this.$set(
              this.form,
              key,
              this.authenticatedUserInfoFromFormbuilder[key]
            );
          }
        }
        if (this.form?.first_name && this.form?.last_name && !this.form.name) {
          this.form.name = this.form.first_name + " " + this.form.last_name;
        }
      }
    },
    defaultMethod() {
      if (
        this.data.default_value &&
        this.form &&
        this.data.key &&
        !this.form[this.data.key]
      ) {
        //  this.form[this.data.key] = this.data.default_value;
        this.$set(this.form, this.data.key, this.data.default_value);
      } else if (
        !this.form[this.data.key] &&
        this.data.default_mapped_field &&
        this.form[this.data.default_mapped_field]
      ) {
        this.$set(
          this.form,
          this.data.key,
          this.form[this.data.default_mapped_field]
        );
      }
      this.$emit("applyFormRules");
    },
    unHiddenPassword() {
      this.password = this.form[this.data.key];
    },
    hidePassword() {
      this.password = this.form[this.data.key]
        ? "*".repeat(this.form[this.data.key].length)
        : "";
    },
    realNumber() {
      this.flag = false;
      this.form[this.data.key] = this.inputValue3;
      this.flag = true;
    },
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id,
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariableById",
        params
      );

      if (this.getGlobalVariableById) {
        if (this.getGlobalVariableById.input_type === "SINGLE_LINE_TEXT") {
          this.isList = true;
          this.form[this.data.key] = this.getGlobalVariableById.value;
        }
      }
    },
    readonly() {
      //If it's an entity varaible, have to check access
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.fieldFilledByCurrentUser(this.data.filled_by) === "false") {
        if (this.data.styles && this.data.styles.labelStyle) {
          if (this.data.styles.labelStyle == "hide") {
            this.showLabel = false;
          }
          // if (this.data.styles.labelStyle == "right") {
          //   this.isDefalutPos = false;
          // }
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      } else {
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === "DISABLED"
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      }
    },

    // rfrs sgwrer

    // ended
    validateEmail() {
      if (
        this.form[this.data.key] &&
        templateConstants.emailPattern.test(this.form[this.data.key])
      ) {
        this.errorMsg = "";
        this.hasError = false;
      } else {
        this.errorMsg = "Invalid Email Format";
        this.hasError = true;
      }
    },
    validatePassword() {
      if (
        this.form[this.data.key] &&
        templateConstants.passwordPattern.test(this.form[this.data.key])
      ) {
        this.errorMsg = "";
        this.hasError = false;
      } else {
        this.errorMsg = "Invalid password Format";
        this.hasError = true;
      }
    },
    validateMasked() {
      this.inputValue3 = this.form[this.data.key];
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.masked_type &&
        this.data.properties.masked_type === "SSN"
      ) {
        this.form[this.data.key] = this.form[this.data.key]
          .replace(/ /g, "")
          .replace(/-/g, "");
        if (
          this.form[this.data.key] &&
          templateConstants.ssnPattern.test(this.form[this.data.key])
        ) {
          this.errorMsg = "";
          this.hasError = false;
          this.inputValue2 = this.form[this.data.key];
          if (this.form[this.data.key].length <= 4) {
            this.maskedValue = this.form[this.data.key];
          } else {
            let lastFourDigits = this.form[this.data.key].slice(-4);
            this.maskedValue =
              "*".repeat(this.form[this.data.key].length - 4) + lastFourDigits;
          }
          this.form[this.data.key] = this.maskedValue;
          // this.form[this.data.key + '_masked'] = this.maskedValue;
          this.$set(this.form, this.data.key + "_masked", this.maskedValue);
          this.$set(this.form, this.data.key + "realValue", this.inputValue3);
        } else {
          this.errorMsg = "Invalid SSN ";
          this.hasError = true;
        }
      } else if (
        this.data &&
        this.data.properties &&
        this.data.properties.masked_type &&
        this.data.properties.masked_type === "CREDIT CARD"
      ) {
        this.form[this.data.key] = this.form[this.data.key]
          .replace(/ /g, "")
          .replace(/-/g, "");
        if (
          this.form[this.data.key] &&
          templateConstants.creditCardPattern.test(this.form[this.data.key])
        ) {
          this.errorMsg = "";
          this.hasError = false;
          this.inputValue2 = this.form[this.data.key];
          if (this.form[this.data.key].length <= 4) {
            this.maskedValue = this.form[this.data.key];
          } else {
            let lastFourDigits = this.form[this.data.key].slice(-4);
            this.maskedValue =
              "*".repeat(this.form[this.data.key].length - 4) + lastFourDigits;
          }
          this.form[this.data.key] = this.maskedValue;
          // this.form[this.data.key + '_masked'] = this.maskedValue;
          this.$set(this.form, this.data.key + "_masked", this.maskedValue);
          this.$set(this.form, this.data.key + "realValue", this.inputValue3);
        } else {
          this.errorMsg = "Invalid Masked Format";
          this.hasError = true;
        }
      } else {
        this.form[this.data.key] = this.form[this.data.key]
          .replace(/ /g, "")
          .replace(/-/g, "");
        if (
          this.form[this.data.key] &&
          templateConstants.ssnPattern.test(this.form[this.data.key])
        ) {
          this.errorMsg = "";
          this.hasError = false;
          this.inputValue2 = this.form[this.data.key];
          if (this.form[this.data.key].length <= 4) {
            this.maskedValue = this.form[this.data.key];
          } else {
            let lastFourDigits = this.form[this.data.key].slice(-4);
            this.maskedValue =
              "*".repeat(this.form[this.data.key].length - 4) + lastFourDigits;
          }
          this.form[this.data.key] = this.maskedValue;
          // this.form[this.data.key + '_masked'] = this.maskedValue;
          this.$set(this.form, this.data.key + "_masked", this.maskedValue);
          this.$set(this.form, this.data.key + "realValue", this.inputValue3);
        } else {
          this.errorMsg = "Invalid SSN ";
          this.hasError = true;
        }
      }
    },
    checkNavigationAction() {
      if (this.data.input_type === "HYPER_LINK") {
        if (
          this.isValidURL(this.form[this.data.key]) &&
          this.isValidHttpUrl(this.getValidUrl(this.form[this.data.key]))
        ) {
          let url = this.getValidUrl(this.form[this.data.key]);
          // this.openURL(url, action);
          window.open(url, "_blank");
        } else {
          this.$message.info("Invalid URL");
        }
      } else {
        this.$message.info("No Hyperlink to open");
      }
    },

    getValidUrl(url) {
      if (!url.includes("http://") && !url.includes("https://"))
        return "https://" + url;
      return url;
    },
    isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    isValidURL(url) {
      let re =
        /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
      if (re.test(url)) {
        return true;
      } else {
        return false;
      }
    },
    enableQRReader() {
      this.qrLoader = true;
      this.showQrReader = true;
      setTimeout(() => {
        this.qrLoader = false;
      }, 1500);
    },
    cancelQRPopup() {
      this.loading = true;
      this.showQrReader = false;

      navigator.mediaDevices.getUserMedia({ video: true });

      navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then((mediaStream) => {
          const stream = mediaStream;
          const tracks = stream.getTracks();
          tracks.forEach((track) => track.stop());
        });
      // this.modalTitle='';
      setTimeout(() => {
        this.loading = false;
      }, 1500);
    },
  },
  watch: {
    // $props: {
    //   handler() {
    //     this.defaultMethod();
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    "data.styles.inputTextAlignments": {
      handler(newVal) {
        // Whenever inputTextAlignments changes, update the computed property.
        this.textAlignment = newVal;
      },
      immediate: true, // This will trigger the handler immediately when the component is created
    },
    "form.first_name": function (newFirstName) {
      this.updateFullName(newFirstName);
    },
    "form.last_name": function (newLastName) {
      this.updateFullName(newLastName);
    },
  },
};
</script>

<style lang="scss" scoped>
.highlight-border {
  border-color: red !important;
}
.sle {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 32px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  @media (max-width: 700px) {
    width: 100% !important;
  }

  &:hover {
    border-color: #409eff;
    /* Change the border color on hover */
  }

  &:focus {
    border-color: #409eff;
    /* Change the border color when focused */
  }

  &::placeholder {
    color: #ccc;
    /* Use your desired lighter gray color here */
  }
  @media only screen and (max-width: 767px) {
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: 16px !important;
    height: 32px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
  }
}

.qrCodeBlock {
  text-align: center;
  padding: 0.2rem;

  .size-2em {
    font-size: 2em !important;
  }
}

.disabled-field {
  background-color: #f5f7fa;
  border-color: #e4e7ed;
  color: #c0c4cc;
  /* Set your preferred greyed-out color */
  pointer-events: painted;
  cursor: not-allowed;
}

.execute-view {
  font-weight: normal;
}
.regenerate {
  font-size: large;
  margin-top: 5px;
  margin-left: 5px;
}
</style>
